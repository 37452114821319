import React, { useState, useEffect, useRef } from 'react';

import { useResizeObserver } from 'usehooks-ts';

import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { saveAs } from 'file-saver';

import { Table, Input, DatePicker, Flex, Button, Popconfirm, Tooltip, Divider, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, DeleteOutlined, FilterFilled, UploadOutlined, DownloadOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import UploadFiles from '@components/documents/upload-files';

import { exception, securityRestriction } from '@extensions/notification';
import { delayAction, dataURItoBlob, getDateTimeLocal } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { IDocument } from '@entities/document';
import { IDocumentFilter } from '@entities/document-filter';
import { IConsignee } from '@entities/consignee';

import { DocumentReferenceType } from '@enums/document-reference-type';
import { Permission, hasPermission } from '@enums/permission';

import { serverFetch } from '@src/core/server';

dayjs.extend(utc);

interface IFile {
    consignee: IConsignee | undefined;
}

const filterContext: string = 'Files';

const Files = (props: IFile) => {
    const { consignee } = props;

    const { RangePicker } = DatePicker;

    const initFilter: IDocumentFilter = { referenceType: DocumentReferenceType.Consignee };

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const filter = useAppSelector<IDocumentFilter>((s) => s.filters[filterContext]);

    const [documents, setDocuments] = useState<Array<IDocument>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);

    const canManageDocuments = hasPermission(userSession.permissions, Permission.ManageTransportBill);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        setDocuments([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('documents', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения документов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setDocuments(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter({ ...initFilter, consigneeId: consignee?.id }, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (!consignee) {
            setDocuments([]);

            return;
        }

        d(setFilter({ ...initFilter, consigneeId: consignee?.id }, filterContext));
    }, [consignee]);

    const onDownload = (document: IDocument) => {
        if (!document) return;

        serverFetch(`documents/${document?.id}/download`, { method: 'GET' })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка загрузки документа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDelete = (document: IDocument) => {
        if (!document) return;

        serverFetch(`documents/${document.id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления документа', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: !consignee || loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Загрузить',
                        disabled: !consignee || loading,
                        key: 'upload',
                        icon: <UploadOutlined />,
                        onClick: () => {
                            if (!canManageDocuments) {
                                securityRestriction(api, [Permission.ManageTransportBill]);
                                return;
                            }

                            setOpenUploadDialog(true);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            key='searchText'
                            placeholder='Название...'
                            value={filter?.searchText}
                            style={{ width: 250 }}
                            onChange={(data) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                        <RangePicker
                            style={{ width: 260 }}
                            allowEmpty={[true, true]}
                            key='created'
                            format='DD.MM.YYYY'
                            placeholder={['Отправлен с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[filter?.dateFrom ? dayjs(filter?.dateFrom) : null, filter?.dateTo ? dayjs(filter?.dateTo) : null]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                dateFrom: undefined,
                                                dateTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            dateFrom: value[0]
                                                ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                            dateTo: value[1]
                                                ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter({ ...initFilter, consigneeId: consignee?.id }, filterContext))}
                />
            </div>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IDocument> = [
            {
                align: 'center',
                width: canManageDocuments ? 70 : 35,
                render: (_, record) => {
                    return (
                        <Flex>
                            <Tooltip title='Скачать'>
                                <Button size='small' type='link' icon={<DownloadOutlined />} onClick={() => onDownload(record)} />
                            </Tooltip>
                            {canManageDocuments && (
                                <Tooltip title='Удалить'>
                                    <Popconfirm
                                        title='Удалить документ'
                                        description={`Удалить документ "${record?.name}"?`}
                                        cancelText='Нет'
                                        okText='Да'
                                        onConfirm={() => onDelete(record)}
                                    >
                                        <Button size='small' type='link' icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </Tooltip>
                            )}
                        </Flex>
                    );
                },
            },
            {
                title: 'Название',
                render: (_, record) => {
                    return record.name;
                },
            },
            {
                title: 'Дата',
                width: 130,
                render: (_, record) => {
                    return getDateTimeLocal(record.createdOn);
                },
            },
        ];

        return (
            <Table
                virtual={true}
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={documents}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        style: {
                            fontWeight: record.isDownloaded ? '' : 600,
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: height ?? 0 }}
            />
        );
    };

    return (
        <>
            <Divider style={{ marginTop: 0 }} orientation='left'>
                Документы
            </Divider>
            <div ref={containerRef} style={{ height: `calc(100vh - 235px - ${filterHeight}px)` }}>
                {renderToolbar()}
                {renderFilter()}
                {renderTable()}
            </div>

            {openUploadDialog && (
                <UploadFiles
                    consignee={consignee}
                    dateFrom={dayjs().utc()}
                    onCancel={() => {
                        setRefreshRequired(true);
                        setOpenUploadDialog(false);
                    }}
                />
            )}

            {contextHolder}
        </>
    );
};

export default Files;
