import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, ColorPicker, InputNumber, Form, Space, Select, Divider, notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { NoColor } from '@src/core/types';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { ICity } from '@entities/city';
import { IUserSession } from '@entities/user-session';
import { ITransportTariff } from '@entities/transport-tariff';

import { Permission, hasPermission } from '@enums/permission';

const TransportTariff = () => {
    const { id, isCopy } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<ITransportTariff>({
        id: undefined,
        cityId: undefined,
        palletPrice: undefined,
        weightPrice: undefined,
        color: NoColor,
    });

    const [cities, setCities] = useState<Array<ICity>>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [canManage] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageTradingPlatforms));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`transporttariffs/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения транспортного тарифа', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0]);
                if (id) {
                    let data = result[0][1] as ITransportTariff;
                    if (isCopy) {
                        data.id = undefined;
                        data.cityId = undefined;
                    }

                    setEntity(data);
                }

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    const onSave = () => {
        setLoading(true);

        serverFetch(`transporttariffs`, { method: id && !isCopy ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения транспортного тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader
                title={`${
                    id && !isCopy
                        ? !canManage
                            ? 'Информация о транспортном тарифе'
                            : 'Изменить транспортный ариф'
                        : 'Добавить транспортный тариф'
                }`}
            />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 7 }} onFinish={onSave}>
                    <Form.Item
                        initialValue={entity.cityId}
                        required
                        label='Расчетный город'
                        name='cityId'
                        rules={[{ required: true, message: 'Выберите город' }]}
                    >
                        <Select
                            autoFocus
                            showSearch
                            disabled={!canManage}
                            onChange={(value) => setEntity({ ...entity, cityId: value })}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        ></Select>
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.weightPrice}
                        required
                        label='Тариф за вес'
                        name='weightPrice'
                        rules={[{ required: true, message: 'Укажите тариф за вес' }]}
                    >
                        <InputNumber
                            style={{ width: 150 }}
                            decimalSeparator=','
                            step='0.1'
                            stringMode
                            disabled={!canManage}
                            onChange={(value) => setEntity({ ...entity, weightPrice: value as number })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.palletPrice}
                        required
                        label='Тариф за паллету'
                        name='palletPrice'
                        rules={[{ required: true, message: 'Укажите тариф за паллета' }]}
                    >
                        <InputNumber
                            style={{ width: 150 }}
                            decimalSeparator=','
                            stringMode={true}
                            disabled={!canManage}
                            onChange={(value) => setEntity({ ...entity, palletPrice: value as number })}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.color || ''} required label='Цвет' name='color'>
                        <ColorPicker
                            allowClear
                            styles={{ popupOverlayInner: { width: 492 } }}
                            //value={value}
                            onChangeComplete={(color) => {
                                setEntity({ ...entity, color: color.toHexString() });
                            }}
                            presets={[
                                {
                                    label: 'Рекомендованные',
                                    colors: [
                                        '#F5222D',
                                        '#FA8C16',
                                        '#FADB14',
                                        '#8BBB11',
                                        '#52C41A',
                                        '#13A8A8',
                                        '#1677FF',
                                        '#2F54EB',
                                        '#722ED1',
                                        '#EB2F96',
                                        '#F5222D4D',
                                        '#FA8C164D',
                                        '#FADB144D',
                                        '#8BBB114D',
                                        '#52C41A4D',
                                        '#13A8A84D',
                                        '#1677FF4D',
                                        '#2F54EB4D',
                                        '#722ED14D',
                                        '#EB2F964D',
                                    ],
                                },
                            ]}
                            panelRender={(_, { components: { Picker, Presets } }) => (
                                <div
                                    className='custom-panel'
                                    style={{
                                        display: 'flex',
                                        width: 468,
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        <Presets />
                                    </div>
                                    <Divider
                                        type='vertical'
                                        style={{
                                            height: 'auto',
                                        }}
                                    />
                                    <div
                                        style={{
                                            width: 234,
                                        }}
                                    >
                                        <Picker />
                                    </div>
                                </div>
                            )}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ offset: 3, span: 5 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {canManage && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default TransportTariff;
