import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';

import {
    Modal,
    Table,
    Tag,
    Form,
    Input,
    InputNumber,
    Select,
    Flex,
    Space,
    Checkbox,
    notification,
    Tooltip,
    Button,
    Upload,
    Switch,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { UploadProps } from 'antd/es/upload';
import type { GetRef } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import {
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    FilterFilled,
    AppstoreAddOutlined,
    QuestionCircleOutlined,
    CheckCircleFilled,
    PaperClipOutlined,
    CheckOutlined,
    CheckCircleOutlined,
    MessageFilled,
    WarningOutlined,
    FormOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import FormHeader from '@controls/form-header/form-header';
import ConsignmentCard from '@controls/consignment-card/consignment-card';
import TariffCard from '@controls/tariff-card/tariff-card';

import Packages from './packages';

import { exception, securityRestriction } from '@extensions/notification';

import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { delayAction, toFinanceString, getEnumList } from '@extensions/utils';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IBill } from '@entities/bill';
import { IBillFilter } from '@entities/bill-filter';
import { IBillPackage } from '@entities/bill-package';
import { ICity } from '@entities/city';
import { IConsigneeTariffSetting } from '@entities/consignee-tariff-setting';
import { ITruck } from '@entities/truck';
import { IUser } from '@entities/user';
import { ICountry } from '@entities/country';
import { IConsignmentTotals } from '@entities/consignment-totals';
import { ICargoCarrier } from '@entities/cargo-carrier';
import { IItem } from '@entities/item';
import { IDeleteBillParams } from '@entities/delete-bill-params';
import { ITradingPlatform } from '@entities/trading-platform';
import { IMarking } from '@entities/marking';

import { Permission, hasPermission } from '@enums/permission';
import { UnitType } from '@enums/unit-type';
import { WeightType } from '@enums/weight-type';
import { UserType } from '@enums/user-type';
import { IEnumItem } from '@enums/enum-item';
import { BillStatus, enumLabel as billStatusLabel } from '@enums/bill-status';

import { TruckIcon, AirplaneIcon, CalculatorIcon, SumIcon } from '@icons/index';

import './bills.css';
import { ICountryItem } from '@src/core/entities/country-item';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export interface IBillParams {
    billIds: Array<string>;
    boxGroupId?: string;
    setQtyRequired: boolean;
    qty?: number;
    setAdjustmentQtyRequired: boolean;
    adjustmentQty?: number; //Size of Pallet or Cart. Default = 1 (can be 0.5)
    flightPrice?: number;
    truckPrice?: number;
    isTotalPriceManual: boolean;
    totalPrice: number;
    jsonPackages?: string;
    comment?: string;
    setCityRequired: boolean;
    cityId?: string;
    setVolumeWeightRequired: boolean;
    volumeWeight?: number;
    setGrossWeightRequired: boolean;
    grossWeight?: number;
    setAwbNumberRequired: boolean;
    awbNumber?: string;
    cargoCarrierId?: string;
    setItemRequired: boolean;
    itemId?: string;
}

interface IBillModalParams {
    bill: IBill;
    cities: Array<ICity>;
    items: Array<ICountryItem>;
    tariffSettings: Array<IConsigneeTariffSetting>;
    airCargoCarriers: Array<ICargoCarrier>;
    onSave: (value: IBillParams) => void;
    onCancel: () => void;
}

const BillModal = (props: IBillModalParams) => {
    const { bill, cities, tariffSettings, airCargoCarriers, items, onSave, onCancel } = props;

    const { TextArea } = Input;

    const [editBillForm] = Form.useForm();

    const setCityRequired = Form.useWatch('setCityRequired', editBillForm);
    const setQtyRequired = Form.useWatch('setQtyRequired', editBillForm);
    const setAdjustmentQtyRequired = Form.useWatch('setAdjustmentQtyRequired', editBillForm);
    const setAwbNumberRequired = Form.useWatch('setAwbNumberRequired', editBillForm);
    const setVolumeWeightRequired = Form.useWatch('setVolumeWeightRequired', editBillForm);
    const setGrossWeightRequired = Form.useWatch('setGrossWeightRequired', editBillForm);
    const setItemRequired = Form.useWatch('setItemRequired', editBillForm);
    const isTotalPriceManual = Form.useWatch('isTotalPriceManual', editBillForm);

    useEffect(() => {
        editBillForm.setFieldsValue({
            id: bill?.id,
            markingId: bill?.markingId,
            itemId: bill?.itemId,
            boxGroupId: bill?.boxGroupId,
            qty: bill?.qty,
            adjustmentQty: bill?.adjustmentQty,
            cityId: bill?.cityId,
            awbNumber: bill?.awbNumber,
            cargoCarrierId: bill?.cargoCarrierId,
            volumeWeight: bill?.volumeWeight,
            grossWeight: bill?.grossWeight,
            flightPrice: bill?.flightPrice,
            truckPrice: bill?.truckPrice,
            totalPrice: bill?.totalPrice,
            isTotalPriceManual: bill?.isTotalPriceManual,
            comment: bill?.comment,
        });
    }, []);

    return (
        <Modal
            width={650}
            title={`Изменить счет для "${bill?.markingCode}"`}
            open={true}
            okText='ОК'
            onOk={() => editBillForm.submit()}
            onCancel={() => {
                editBillForm.resetFields();
                onCancel();
            }}
        >
            <Form
                colon={false}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                onFinish={onSave}
                form={editBillForm}
                style={{ marginTop: 20 }}
            >
                <Form.Item name='id' hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name='boxGroupId' hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item label='Продукт' style={{ marginBottom: 0 }}>
                    <Flex gap='small'>
                        <Form.Item name='setItemRequired' valuePropName='checked'>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            name='itemId'
                            rules={[{ required: setItemRequired, message: 'Выберите продукт' }]}
                            style={{ width: '100%' }}
                        >
                            <Select
                                disabled={!setItemRequired}
                                showSearch
                                allowClear
                                filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                                options={items.map((i) => {
                                    return { value: i.itemId, label: i.itemName };
                                })}
                            ></Select>
                        </Form.Item>
                    </Flex>
                </Form.Item>

                <Form.Item label='Расчетный город' style={{ marginBottom: 0 }}>
                    <Flex gap='small'>
                        <Form.Item name='setCityRequired' valuePropName='checked'>
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            name='cityId'
                            rules={[{ required: setCityRequired, message: 'Выберите город' }]}
                            style={{ width: '100%' }}
                        >
                            <Select
                                disabled={!setCityRequired}
                                showSearch
                                allowClear
                                filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                                options={cities.map((c) => {
                                    return { value: c.id, label: c.name };
                                })}
                            ></Select>
                        </Form.Item>
                    </Flex>
                </Form.Item>

                <Form.Item
                    label={
                        bill?.unitType == UnitType.Cart
                            ? 'Телега'
                            : bill?.unitType == UnitType.Pallet && (!bill?.packages || bill.packages.length <= 0)
                            ? 'Паллета'
                            : 'Коробки'
                    }
                    style={{ marginBottom: 0 }}
                >
                    <Flex gap='large'>
                        {(bill?.unitType == UnitType.Weight ||
                            (bill?.unitType == UnitType.Pallet && bill?.packages && bill?.packages.length > 0)) && (
                            <Flex gap='small'>
                                <Form.Item name='setQtyRequired' valuePropName='checked'>
                                    <Checkbox />
                                </Form.Item>
                                <Form.Item name='qty' style={{ marginBottom: 0 }}>
                                    <InputNumber
                                        disabled={!setQtyRequired}
                                        addonAfter='шт'
                                        min={0}
                                        step='1'
                                        onChange={(value: number | null) => {
                                            let qty: number = value || 0;
                                            editBillForm.setFieldValue('qty', qty);
                                        }}
                                    />
                                </Form.Item>
                            </Flex>
                        )}

                        {bill?.unitType != UnitType.Weight && (!bill?.packages || bill.packages.length <= 0) && (
                            <Flex gap='small'>
                                <Form.Item name='setAdjustmentQtyRequired' valuePropName='checked'>
                                    <Checkbox />
                                </Form.Item>
                                <Form.Item name='adjustmentQty' style={{ marginBottom: 0 }}>
                                    <InputNumber
                                        disabled={!setAdjustmentQtyRequired}
                                        decimalSeparator=','
                                        addonAfter='шт'
                                        min={0}
                                        step='0.1'
                                        stringMode
                                        precision={2}
                                        onChange={(value: number | null) => {
                                            let adjustmentQty: number = value || 0;
                                            let totalPrice = adjustmentQty * editBillForm.getFieldValue('truckPrice');

                                            editBillForm.setFieldValue('adjustmentQty', adjustmentQty);
                                            editBillForm.setFieldValue('totalPrice', +totalPrice);
                                        }}
                                    />
                                </Form.Item>
                            </Flex>
                        )}

                        <Form.Item label='AWB' style={{ width: '100%', marginBottom: 0 }}>
                            <Flex gap='small'>
                                <Form.Item name='setAwbNumberRequired' valuePropName='checked'>
                                    <Checkbox />
                                </Form.Item>
                                <Form.Item
                                    name='awbNumber'
                                    rules={[{ required: setAwbNumberRequired, message: 'Укажите AWB' }]}
                                    style={{ width: '100%' }}
                                >
                                    <Input
                                        disabled={!setAwbNumberRequired}
                                        onChange={(data) => {
                                            editBillForm.setFieldValue('awbNumber', data.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Flex>
                        </Form.Item>
                    </Flex>
                </Form.Item>

                {bill?.unitType == UnitType.Weight && (
                    <>
                        <Form.Item label='Вес Брутто' style={{ marginBottom: 0 }}>
                            <Flex gap='middle'>
                                <Flex gap='small'>
                                    <Form.Item name='setGrossWeightRequired' valuePropName='checked'>
                                        <Checkbox />
                                    </Form.Item>
                                    <Form.Item
                                        name='grossWeight'
                                        rules={[{ required: setGrossWeightRequired, message: 'Укажите вес Брутто' }]}
                                    >
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            decimalSeparator=','
                                            min={0}
                                            stringMode
                                            precision={2}
                                            disabled={!setGrossWeightRequired}
                                            onChange={(value: number | null) => {
                                                let grossWeight = value || 0;
                                                let setting = tariffSettings.find(
                                                    (s) => s.consigneeId == bill?.consigneeId && s.countryId == bill?.countryId
                                                );

                                                if (setting && setting.weightType == WeightType.Gross) {
                                                    let totalPrice =
                                                        ((editBillForm.getFieldValue('flightPrice') || 0) +
                                                            editBillForm.getFieldValue('truckPrice')) *
                                                        grossWeight;

                                                    editBillForm.setFieldValue('totalPrice', totalPrice);
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Flex>

                                <Form.Item label='Объемный вес' style={{ marginBottom: 0 }}>
                                    <Flex gap='small'>
                                        <Form.Item name='setVolumeWeightRequired' valuePropName='checked'>
                                            <Checkbox />
                                        </Form.Item>
                                        <Form.Item
                                            name='volumeWeight'
                                            rules={[{ required: setVolumeWeightRequired, message: 'Укажите объемный вес' }]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                decimalSeparator=','
                                                min={0}
                                                stringMode
                                                precision={2}
                                                disabled={!setVolumeWeightRequired}
                                                onChange={(value: number | null) => {
                                                    let volumeWeight: number = value || 0;

                                                    let setting = tariffSettings.find(
                                                        (s) => s.consigneeId == bill?.consigneeId && s.countryId == bill?.countryId
                                                    );

                                                    if (!setting || setting.weightType == WeightType.Volume) {
                                                        let flightPrice: number = editBillForm.getFieldValue('flightPrice') || 0;
                                                        let truckPrice: number = editBillForm.getFieldValue('truckPrice') || 0;

                                                        let totalPrice = (+flightPrice + +truckPrice) * +volumeWeight;

                                                        editBillForm.setFieldValue('totalPrice', +totalPrice);
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Flex>
                                </Form.Item>
                            </Flex>
                        </Form.Item>

                        <Form.Item label='Авиаперевозчик' name='cargoCarrierId' wrapperCol={{ span: 8 }}>
                            <Select
                                allowClear={true}
                                onChange={(value) => {
                                    editBillForm.setFieldValue('cargoCarrierId', value);
                                }}
                                filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                                options={airCargoCarriers
                                    .filter((c) => c.countryId == bill.countryId)
                                    .map((c) => {
                                        return { value: c.id, label: c.name };
                                    })}
                            />
                        </Form.Item>
                    </>
                )}

                <Form.Item label='Тариф' style={{ marginBottom: 0 }}>
                    <Flex gap='middle'>
                        <Form.Item name='flightPrice' style={{ marginBottom: 0 }}>
                            <InputNumber
                                decimalSeparator=','
                                disabled={bill?.unitType != UnitType.Weight /* || isTotalPriceManual */}
                                addonAfter={
                                    <Tooltip title='Перелет' placement='topRight'>
                                        <div>
                                            <AirplaneIcon />
                                        </div>
                                    </Tooltip>
                                }
                                min={0}
                                step='0.1'
                                stringMode
                                precision={2}
                                onChange={(value: number | null) => {
                                    let flightPrice: number | null = value;

                                    let setting = tariffSettings.find(
                                        (s) => s.consigneeId == bill?.consigneeId && s.countryId == bill?.countryId
                                    );

                                    let weight: number =
                                        setting && setting.weightType == WeightType.Gross
                                            ? editBillForm.getFieldValue('grossWeight') || 0
                                            : editBillForm.getFieldValue('volumeWeight') || 0;

                                    let truckPrice: number = editBillForm.getFieldValue('truckPrice');

                                    let totalPrice: number = (+(flightPrice || 0) + +truckPrice) * +weight;

                                    editBillForm.setFieldValue('flightPrice', flightPrice);
                                    editBillForm.setFieldValue('totalPrice', totalPrice);
                                }}
                            />
                        </Form.Item>
                        <Form.Item name='truckPrice' style={{ marginBottom: 0 }}>
                            <InputNumber
                                //disabled={isTotalPriceManual}
                                decimalSeparator=','
                                addonAfter={
                                    <Tooltip
                                        title={bill?.cargoCarrierId ? 'Транспортная доставка с перелетом' : 'Транспортная доставка'}
                                        placement='topRight'
                                    >
                                        <div>
                                            <TruckIcon style={{ color: bill?.cargoCarrierId ? 'var(--cargo-color)' : '' }} />
                                        </div>
                                    </Tooltip>
                                }
                                min={0}
                                step='0.1'
                                stringMode
                                precision={2}
                                onChange={(value: number | null) => {
                                    let truckPrice: number = value || 0;

                                    let totalPrice: number = 0;
                                    if (bill?.unitType == UnitType.Weight) {
                                        let setting = tariffSettings.find(
                                            (s) => s.consigneeId == bill?.consigneeId && s.countryId == bill?.countryId
                                        );

                                        let weight: number =
                                            setting && setting.weightType == WeightType.Gross
                                                ? editBillForm.getFieldValue('grossWeight') || 0
                                                : editBillForm.getFieldValue('volumeWeight') || 0;

                                        let flightPrice: number = editBillForm.getFieldValue('flightPrice') || 0;
                                        totalPrice = (+flightPrice + +truckPrice) * +weight;
                                    } else {
                                        if (bill?.packages && bill?.packages.length > 0) {
                                            bill?.packages?.map((p) => {
                                                totalPrice += ((p.boxQty || 0) / p.qtyPerPallet) * truckPrice;
                                            });
                                        } else {
                                            totalPrice = editBillForm.getFieldValue('adjustmentQty') * truckPrice;
                                        }
                                    }

                                    editBillForm.setFieldValue('truckPrice', truckPrice);
                                    editBillForm.setFieldValue('totalPrice', totalPrice);
                                }}
                            />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Flex gap='small'>
                                <Tooltip title='Задать итоговую сумму вручную'>
                                    <Form.Item name='isTotalPriceManual' valuePropName='checked'>
                                        <Checkbox
                                            onChange={(value: CheckboxChangeEvent) => {
                                                let isManual: boolean = value.target.checked;

                                                if (!isManual) {
                                                    let totalPrice: number = 0;
                                                    let flightPrice: number = editBillForm.getFieldValue('flightPrice') || 0;
                                                    let truckPrice: number = editBillForm.getFieldValue('truckPrice') || 0;

                                                    if (bill?.unitType == UnitType.Weight) {
                                                        let setting = tariffSettings.find(
                                                            (s) => s.consigneeId == bill?.consigneeId && s.countryId == bill?.countryId
                                                        );

                                                        let weight: number =
                                                            setting && setting.weightType == WeightType.Gross
                                                                ? editBillForm.getFieldValue('grossWeight') || 0
                                                                : editBillForm.getFieldValue('volumeWeight') || 0;

                                                        totalPrice = (+flightPrice + +truckPrice) * +weight;
                                                    } else {
                                                        if (bill?.packages && bill?.packages.length > 0) {
                                                            bill?.packages?.map((p) => {
                                                                totalPrice += ((p.boxQty || 0) / p.qtyPerPallet) * truckPrice;
                                                            });
                                                        } else {
                                                            totalPrice = editBillForm.getFieldValue('adjustmentQty') * truckPrice;
                                                        }
                                                    }

                                                    editBillForm.setFieldValue('totalPrice', totalPrice);
                                                }

                                                editBillForm.setFieldValue('isTotalPriceManual', isManual);
                                            }}
                                        />
                                    </Form.Item>
                                </Tooltip>

                                <Form.Item name='totalPrice' rules={[{ required: isTotalPriceManual, message: 'Укажите итоговую сумму' }]}>
                                    <InputNumber
                                        decimalSeparator=','
                                        addonAfter={<SumIcon />}
                                        min={0}
                                        precision={2}
                                        disabled={!isTotalPriceManual}
                                    />
                                </Form.Item>
                            </Flex>
                        </Form.Item>
                    </Flex>
                </Form.Item>
                <Form.Item
                    label='Комментарий'
                    name='comment'
                    rules={[{ required: isTotalPriceManual, message: 'Укажите причину ввода суммы вручную' }]}
                >
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default BillModal;
