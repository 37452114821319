import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Row, Col, Input, Form, Modal, Table, Select, Tooltip, Divider, Switch, Flex, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { CloudUploadOutlined, DeleteOutlined, PlusOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';
import { serverFetch } from '@src/core/server';

import { IUser } from '@entities/user';
import { IUserFilter } from '@entities/user-filter';

import { UserType } from '@enums/user-type';

import { MobileIcon, MobileNoneIcon } from '@icons/index';

const guidEmpty = '00000000-0000-0000-0000-000000000000';

interface INotificationParams {
    subject?: string;
    body: string | undefined;
    recipients?: Array<string>;
    sendPush?: boolean;
    sendEmail?: boolean;
}

const Notification = () => {
    const navigate = useNavigate();

    const { TextArea } = Input;

    const fileInputRef = useRef(null);
    const [notificationForm] = Form.useForm();
    const [recipientsForm] = Form.useForm();

    const sendPush = Form.useWatch('sendPush', notificationForm);
    const sendEmail = Form.useWatch('sendEmail', notificationForm);

    const d = useAppDispatch();

    const [modal, modalContextHolder] = Modal.useModal();

    const [api, contextHolder] = notification.useNotification();

    const [loading, setLoading] = useState<boolean>(false);

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [users, setUsers] = useState<Array<IUser>>([]);

    const [recipients, setRecipients] = useState<Array<IUser>>([]);
    const [userOpen, setUserOpen] = useState<boolean>(false);

    useEffect(() => {
        let filter: IUserFilter = {
            isArchived: false,
            isActive: true,
            types: [UserType.MainClient, UserType.Supplier],
            firebaseTokenOnly: true,
        };

        serverFetch('users', { method: 'GET', queryParams: filter })
            .then((data) => {
                setUsers(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения пользователей', ex, () => d(userLoaded(undefined)));
            });
    }, []);

    const onSendNotification = (entity: INotificationParams) => {
        let ids: Array<string> = [];
        recipients.map((r) => {
            if (r.id && r.firebaseToken) ids.push(r.id);
        });

        entity.recipients = ids;

        if (ids.length <= 0) {
            modal.confirm({
                title: `Отправить массовую рассылку?`,
                icon: <QuestionCircleOutlined />,
                okType: 'primary',
                okText: 'ОК',
                cancelText: 'Отмена',
                onOk: () => onSend(entity),
            });
            return;
        } else {
            onSend(entity);
        }
    };

    const onSend = (entity: INotificationParams) => {
        serverFetch(`notifications`, { method: 'POST', bodyData: entity })
            .then(() => {
                notificationForm.resetFields();

                if (entity.sendPush) {
                    navigate(`/notifications/push`);
                } else {
                    navigate(`/notifications/emails`);
                }
            })
            .catch((ex) => {
                exception(api, 'Ошибка отправления рассылки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onAddRecipient = (data: IUser) => {
        let tmpUsers: Array<IUser> = [...recipients];

        let tmpUser = tmpUsers.find((u) => u.id === data.id);
        if (!tmpUser) {
            let user = users.find((u) => u.id === data.id);
            user && tmpUsers.push(user);
            setRecipients(tmpUsers);
        }

        recipientsForm.resetFields();
        setUserOpen(false);
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Добавить получателя',
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            setUserOpen(true);
                        },
                    },
                    {
                        label: 'Удалить получателя',
                        key: 'delete',
                        icon: <DeleteOutlined />,
                        disabled: loading || selectedIds.length <= 0,
                        onClick: () => {
                            modal.confirm({
                                title: `Удалить выбранных получателей?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    let tmpRecipients = [...recipients];

                                    selectedIds.map((id) => {
                                        let tmpRecipient = tmpRecipients.find((p) => p.id === id);
                                        if (tmpRecipient) {
                                            var index = tmpRecipients.indexOf(tmpRecipient);
                                            if (index !== -1) {
                                                tmpRecipients.splice(index, 1);
                                            }
                                        }
                                    });

                                    setRecipients(tmpRecipients);
                                },
                            });
                        },
                    },
                    {
                        label: 'Импорт',
                        key: 'import',
                        icon: <CloudUploadOutlined />,
                        onClick: () => {
                            if (fileInputRef && fileInputRef.current) {
                                const current = fileInputRef.current as HTMLInputElement;
                                current.click();
                            }
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);
    };

    const renderTable = () => {
        const columns: ColumnsType<IUser> = [
            {
                title: 'ИНН',
                width: 120,
                render: (_, record) => {
                    return (
                        <Tooltip title={record.id !== guidEmpty ? undefined : 'Грузополучатель не найден'}>
                            <span style={{ color: record.id !== guidEmpty ? '' : 'red' }}>{record.inn}</span>
                        </Tooltip>
                    );
                },
            },
            {
                width: 20,
                align: 'center',
                render: (_, record) => {
                    return record.firebaseToken ? (
                        <Tooltip title='Приложение "My Freshline" используется'>
                            <MobileIcon style={{ color: 'green', fontSize: 20 }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Приложение "My Freshline" не используется.'>
                            <MobileNoneIcon style={{ color: 'red', fontSize: 20 }} />
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Контрагент',
                dataIndex: 'legalName',
                width: 250,
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 250,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 150,
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={recipients}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 180px)`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            <Row>
                <Col span={8}>
                    <Divider style={{ marginTop: 0 }} orientation='left'>
                        Новое сообщение
                    </Divider>
                    <Form colon={false} wrapperCol={{ span: 24 }} onFinish={onSendNotification} form={notificationForm}>
                        <Form.Item name='type' rules={[{ required: !sendEmail && !sendPush, message: 'Укажите тип уведомления' }]}>
                            <Flex gap='large'>
                                <Form.Item style={{ margin: 0 }} label='PUSH' valuePropName='checked' name='sendPush'>
                                    <Switch />
                                </Form.Item>

                                <Form.Item style={{ margin: 0 }} label='Email' valuePropName='checked' name='sendEmail'>
                                    <Switch />
                                </Form.Item>
                            </Flex>
                        </Form.Item>

                        <Form.Item name='subject' rules={[{ required: sendEmail, message: 'Укажите заголовок' }]}>
                            <Input placeholder='Заголовок...' maxLength={512} />
                        </Form.Item>

                        <Form.Item name='body' rules={[{ required: true, message: 'Введите текст сообщения' }]}>
                            <TextArea placeholder='Cообщение...' rows={11} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type='primary' htmlType='submit' loading={loading} style={{ float: 'right' }}>
                                Отправить
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col offset={1} span={15}>
                    <Divider style={{ marginTop: 0 }} orientation='left'>
                        Получатели
                    </Divider>
                    {renderToolbar()}
                    {renderTable()}
                </Col>
            </Row>

            <input
                type='file'
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                onChange={(x) => {
                    if (!x.target || !x.target.files) return;

                    let file = x.target.files[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            var result = reader?.result as string;

                            var fileBody = result.replace(
                                'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
                                ''
                            );

                            setRecipients([]);

                            setLoading(true);

                            serverFetch(`users/getbyinn`, { method: 'POST', bodyData: fileBody })
                                .then((data: Array<IUser>) => {
                                    setLoading(false);

                                    let index: number = 0;
                                    data.map((u) => {
                                        if (u.id === guidEmpty) {
                                            u.id = String(index);
                                            ++index;
                                        }
                                    });

                                    setRecipients(data);
                                })
                                .catch((ex) => {
                                    setLoading(false);
                                    exception(api, 'Ошибка получения получателей', ex, () => d(userLoaded(undefined)));
                                });
                        };

                        reader.onerror = (error) => console.log(error);
                    }
                }}
                onClick={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = '';
                }}
                multiple={false}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />

            <Modal
                maskClosable={false}
                title='Добавить получателя'
                open={userOpen}
                okText='ОК'
                onOk={() => recipientsForm.submit()}
                onCancel={() => {
                    recipientsForm.resetFields();
                    setUserOpen(false);
                }}
                width={400}
            >
                <Form colon={false} wrapperCol={{ span: 24 }} style={{ marginTop: 20 }} onFinish={onAddRecipient} form={recipientsForm}>
                    <Form.Item name='id' required={true} rules={[{ required: true, message: 'Выберите получателя' }]}>
                        <Select
                            showSearch
                            autoFocus
                            placeholder='Получатель'
                            size='middle'
                            allowClear
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={users.map((u) => {
                                let label = u.login + (u.legalName ? ` / ${u.legalName}` : '') + (u.inn ? ` / ${u.inn}` : '');
                                return { value: u.id, label: label };
                            })}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Notification;
