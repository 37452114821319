import React, { useState, useEffect } from 'react';

import { Form, Modal, Select, Input, InputNumber, Flex, Button } from 'antd';
import { NotificationInstance } from 'antd/lib/notification/interface';
import { PlusOutlined } from '@ant-design/icons';

import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import Account from '@controls/account';

import { getEnumList, toFinanceString } from '@extensions/utils';
import { warning, exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';

import { IUserAccount } from '@entities/user-account';
import { IUser } from '@entities/user';
import { ISystemSetting } from '@entities/system-setting';
import { ITransaction } from '@entities/transaction';
import { ITransactionParams } from '@entities/transaction-params';

import { IEnumItem } from '@enums/enum-item';
import { Currency, enumSign as currencySign } from '@enums/currency';
import { ExchangeRateType, enumLabel as rateTypeLabel } from '@enums/exchange-rate-type';
import { ExchangeRateCommissionType } from '@enums/exchange-rate-commission-type';
import { SystemSettingType } from '@enums/system-setting-type';
import { UserAccountType } from '@enums/user-account-type';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ITransfer {
    userId: string;
    userLogin: string;
    accounts: Array<IUserAccount>;
    transaction?: ITransaction;
    onSave: (value: ITransactionParams) => void;
    onCancel: () => void;
    api: NotificationInstance;
}

const TransferAccountsModal = (props: ITransfer) => {
    const d = useAppDispatch();

    const [form] = Form.useForm();

    const { userLogin, userId, accounts, transaction, onSave, onCancel, api } = props;

    const [currentFromUserAccount, setCurrentFromUserAccount] = useState<IUserAccount>();
    const [currentUserAccount, setCurrentUserAccount] = useState<IUserAccount>();
    const [rateTypes] = useState<Array<IEnumItem>>(getEnumList(ExchangeRateType, rateTypeLabel));
    const [rateCommissionTypes] = useState<Array<IEnumItem>>(getEnumList(ExchangeRateCommissionType));

    const [fromAccounts] = useState<Array<IUserAccount>>(accounts.filter((a) => a.type === UserAccountType.User));
    const [toAccounts] = useState<Array<IUserAccount>>(accounts.filter((a) => a.type !== UserAccountType.User));

    const [cbUsdExchangeRate, setCbUsdExchangeRate] = useState<number | undefined>();
    const [internalUsdExchangeRate, setInternalUsdExchangeRate] = useState<number | undefined>();

    const [currentRateType, setCurrentRateType] = useState<ExchangeRateType | undefined>(ExchangeRateType.CentralBank);

    const [user, setUser] = useState<IUser>();

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            let promises = [
                await serverFetch(`users/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('systemSettings/cbusdexchangerate', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения курса ЦБ', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`systemSettings/${SystemSettingType.InternalExchangeRate}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения внутреннего курса', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setUser(result[0][0]);
                setCbUsdExchangeRate(result[0][1]);

                let internalUsdExchangeRateSetting: ISystemSetting = result[0][2];
                setInternalUsdExchangeRate(
                    internalUsdExchangeRateSetting && internalUsdExchangeRateSetting.value ? +internalUsdExchangeRateSetting.value : 0
                );

                if (transaction) {
                    form.setFieldValue('transactionId', transaction.id);

                    setCurrentUserAccount(accounts.find((a) => a.id == transaction.userAccountId));
                    setCurrentRateType(transaction.exchangeRateType);

                    let fromAcount = accounts.find((a) => a.id == transaction.fromUserAccountId);
                    if (fromAcount) {
                        fromAcount.amount = transaction.fromAmount;
                        setCurrentFromUserAccount(fromAcount);
                    }
                }

                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (transaction) {
            form.setFieldsValue({
                userId: userId,
                exchangeRateCommissionType: transaction.exchangeRateCommissionType,
                exchangeRateCommission: transaction.exchangeRateCommission,
            });
        } else {
            if (user && user.userSettings) {
                form.setFieldsValue({
                    userId: userId,
                    exchangeRateCommissionType: user.userSettings.exchangeRateCommissionType || ExchangeRateCommissionType.Percentage,
                    exchangeRateCommission: user.userSettings.exchangeRateCommission,
                });

                if (user.userSettings.useInternalExchangeRate) {
                    setCurrentRateType(ExchangeRateType.Internal);
                }
            } else {
                form.setFieldsValue({
                    userId: userId,
                    exchangeRateCommissionType: ExchangeRateCommissionType.Percentage,
                });
            }
        }
    }, [user]);

    useEffect(() => {
        if (currentFromUserAccount?.currency == Currency.Rub && currentUserAccount?.currency == Currency.Usd) {
            if (transaction) {
                form.setFieldValue('exchangeRate', transaction.exchangeRate);
            } else {
                form.setFieldValue(
                    'exchangeRate',
                    currentRateType && currentRateType == ExchangeRateType.CentralBank ? cbUsdExchangeRate : internalUsdExchangeRate
                );
            }
        } else {
            form.setFieldValue('exchangeRate', undefined);
        }

        calcaulateAmount();
    }, [currentFromUserAccount, currentUserAccount, currentRateType]);

    const onSaveTransaction = (entity: ITransactionParams) => {
        if (!entity.amount) return;

        let fromAccount = accounts.find((a) => a.id == entity.fromUserAccountId);

        if (!fromAccount?.amount || fromAccount?.amount < entity.amount) {
            warning(api, 'Недостаточно средств');
            return;
        }

        entity.currency = fromAccount.currency;

        onSave(entity);
    };

    const calcaulateAmount = () => {
        let amount = form.getFieldValue('amount');

        if (!amount) {
            form.setFieldValue('amount', undefined);
            return;
        }

        let exchangeRate = form.getFieldValue('exchangeRate');

        let rateCommission = form.getFieldValue('exchangeRateCommission');
        let rateCommissionType: ExchangeRateCommissionType = form.getFieldValue('exchangeRateCommissionType');

        if (rateCommission && rateCommissionType) {
            if (rateCommissionType == ExchangeRateCommissionType.Percentage) {
                exchangeRate += exchangeRate * rateCommission * 0.01;
            } else {
                exchangeRate += rateCommission;
            }
        }

        if (exchangeRate) {
            var result = amount / exchangeRate;
            form.setFieldValue('resultAmount', result);
        } else {
            form.setFieldValue('resultAmount', amount);
        }
    };

    return (
        <Modal
            destroyOnClose={true}
            width={650}
            title={transaction ? `Изменение перевода между счетами "${userLogin}"` : `Перевод между счетами "${userLogin}"`}
            open={true}
            okText='ОК'
            onOk={() => {
                form.submit();
            }}
            onCancel={() => onCancel()}
        >
            {user && (
                <Form
                    colon={false}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ marginTop: 20 }}
                    form={form}
                    onFinish={onSaveTransaction}
                >
                    <Form.Item hidden name='transactionId'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='userId'>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='exchangeRateCommissionType'>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name='fromUserAccountId'
                        label='Счет cписания'
                        rules={[{ required: true, message: 'Укажите счет для списания' }]}
                        initialValue={transaction?.fromUserAccountId}
                    >
                        <Select
                            disabled={!!transaction}
                            onChange={(value: string) => {
                                setCurrentFromUserAccount(fromAccounts.find((a) => a.id == value));
                                form.setFieldValue('userAccountId', undefined);
                            }}
                            options={fromAccounts.map((a) => {
                                return {
                                    value: a.id,
                                    label: (
                                        <Flex align='center' gap='small'>
                                            <Account type={a.type} currency={a.currency} />
                                            <span style={{}}>{toFinanceString(a.amount || 0, 2)}</span>
                                        </Flex>
                                    ),
                                };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        name='userAccountId'
                        label='Счет пополнения'
                        rules={[{ required: true, message: 'Укажите счет для пополнения' }]}
                        initialValue={transaction?.userAccountId}
                    >
                        <Select
                            disabled={!!transaction}
                            onChange={(value: string) => {
                                setCurrentUserAccount(toAccounts.find((a) => a.id == value));
                            }}
                            options={toAccounts.map((a) => {
                                return {
                                    value: a.id,
                                    label: (
                                        <Flex align='center' gap='small'>
                                            <Account type={a.type} currency={a.currency} />
                                            <span style={{}}>{toFinanceString(a.amount || 0, 2)}</span>
                                        </Flex>
                                    ),
                                };
                            })}
                        />
                    </Form.Item>
                    <Form.Item label='Сумма' wrapperCol={{ span: 24 }}>
                        <Flex>
                            <Form.Item
                                name='amount'
                                wrapperCol={{ span: 6 }}
                                rules={[{ required: true, message: 'Укажите сумму' }]}
                                initialValue={transaction?.fromAmount}
                            >
                                <InputNumber
                                    disabled={!!transaction}
                                    precision={2}
                                    decimalSeparator=','
                                    min={0}
                                    style={{ width: '170px' }}
                                    onChange={(value: number | null) => {
                                        form.setFieldValue('amount', value);
                                        calcaulateAmount();
                                    }}
                                />
                            </Form.Item>
                            {currentFromUserAccount && (
                                <Button
                                    style={{ color: '#1677ff', fontSize: 16 }}
                                    type='link'
                                    onClick={() => {
                                        form.setFieldValue('amount', currentFromUserAccount.amount);
                                        calcaulateAmount();
                                    }}
                                >
                                    {toFinanceString(currentFromUserAccount.amount, 2)}
                                    {currencySign(currentFromUserAccount.currency)}
                                </Button>
                            )}
                        </Flex>
                    </Form.Item>
                    {currentFromUserAccount?.currency == Currency.Rub && (
                        <Form.Item
                            initialValue={currentRateType}
                            label='Источник курса'
                            name='exchangeRateType'
                            rules={[{ required: true, message: 'Укажите источник курса' }]}
                        >
                            <Select
                                onChange={(value: ExchangeRateType) => {
                                    setCurrentRateType(value);
                                }}
                                options={rateTypes.map((a) => {
                                    return { value: a.value, label: a.label };
                                })}
                            />
                        </Form.Item>
                    )}

                    {currentUserAccount && currentFromUserAccount && currentFromUserAccount.currency !== currentUserAccount.currency && (
                        <Form.Item label='Курс'>
                            <Flex align='start' gap='middle'>
                                <Form.Item
                                    name='exchangeRate'
                                    rules={[{ required: true, message: 'Укажите курс' }]}
                                    style={{ width: '50%', marginBottom: 0 }}
                                >
                                    <InputNumber
                                        decimalSeparator=','
                                        precision={4}
                                        style={{ width: '100%' }}
                                        min={0}
                                        onChange={(value: number | null) => {
                                            form.setFieldValue('exchangeRate', value);
                                            calcaulateAmount();
                                        }}
                                    />
                                </Form.Item>

                                <PlusOutlined style={{ height: 30 }} />

                                <Form.Item
                                    name='exchangeRateCommission'
                                    style={{ width: '50%', marginBottom: 0 }}
                                    initialValue={transaction?.exchangeRateCommission}
                                >
                                    <InputNumber
                                        precision={2}
                                        decimalSeparator=','
                                        min={0}
                                        onChange={(value: number | null) => {
                                            form.setFieldValue('exchangeRateCommission', value);
                                            calcaulateAmount();
                                        }}
                                        addonAfter={
                                            <Select
                                                defaultValue={
                                                    transaction?.exchangeRateCommissionType ||
                                                    user.userSettings.exchangeRateCommissionType ||
                                                    ExchangeRateCommissionType.Percentage
                                                }
                                                disabled={!currentUserAccount}
                                                onChange={(value: ExchangeRateCommissionType) => {
                                                    form.setFieldValue('exchangeRateCommissionType', value);
                                                    calcaulateAmount();
                                                }}
                                                options={rateCommissionTypes.map((c) => {
                                                    return {
                                                        value: c.value,
                                                        label:
                                                            c.value == ExchangeRateCommissionType.Percentage
                                                                ? '%'
                                                                : currencySign(currentFromUserAccount?.currency),
                                                    };
                                                })}
                                            />
                                        }
                                    />
                                </Form.Item>
                            </Flex>
                        </Form.Item>
                    )}

                    <Form.Item name='resultAmount' label='ИТОГО'>
                        <InputNumber
                            decimalSeparator=','
                            disabled={true}
                            precision={2}
                            min={0}
                            style={{ width: '170px', background: '#f6fefa' }}
                        />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export default TransferAccountsModal;
